<template>
  <div
    class="mb-0"
    :class="$vuetify.breakpoint.name === 'lg' ? 'pr-12 pl-8' : 'px-4'"
  >

    <!-- ==== DATA SALES DAILY ==== -->
    <div class="my-5">
      <div class="title text--secondary mb-3">Data Penjualan Harian</div>
      <div class="mb-3 d-flex align-center flex-wrap">
        
        <Export
          class=" mr-2"
          :disabled="$store.state.loading"
          :file_type="'xls'"
          :btnText="'Export Excel'"
          :btnIcon="'mdi-microsoft-excel'"
          :btnColor="'teal'"
          :url="`franchise/report/harian/export_excel?store_id=${selected_store_id}`"
          :title="'Laporan Penjualan Harian'"
          ref="base-export-excel"
          v-on:onClickExport="responseExport"
        />
        <Export
          class=" mr-2"
          :disabled="$store.state.loading"
          :file_type="'pdf'"
          :btnText="'Export PDF'"
          :btnIcon="'mdi-file-pdf-box'"
          :btnColor="'#f40f02'"
          :url="`franchise/report/harian/export_pdf?store_id=${selected_store_id}`"
          :title="'Laporan Penjualan Harian'"
          ref="base-export-pdf"
          v-on:onClickExport="responseExport"
        />

        <v-btn
          depressed
          :fab="$vuetify.breakpoint.name !== 'xs' ? false : true"
          :x-small="$vuetify.breakpoint.name !== 'xs' ? false : true"
          :width="$vuetify.breakpoint.name === 'xs' ? '' : '150'"
          elevation="0"
          :disabled="process.run"
          color="#aaa9ad"
          class="white--text text-capitalize"
          @click="$refs['base-table'].isFilterVisible = true"
        >
          <span v-if="$vuetify.breakpoint.name !== 'xs'">Filter</span>
          <v-icon v-if="$vuetify.breakpoint.name === 'xs'">mdi-filter-variant</v-icon>
        </v-btn>
      </div>

      <!-- ==== FILTER STATUS ==== -->
      <div v-if="status.date || status.cashier || status.store">
        Pencarian :
        <v-chip
          v-if="status.date"
          class="ma-2"
          close
          @click:close="handleCloseChips('date')"
        >
          Tanggal: {{ filterdate | date }}
        </v-chip>
      </div>

      <!-- ==== DATA SUMMARY ==== -->
      <v-expansion-panels flat accordion v-model="panel" focusable>
        <v-expansion-panel expand v-model="panel">
          <v-expansion-panel-header
            class="title text--secondary"
            style="font-size: 1rem !important"
            >Rincian Penjualan Harian</v-expansion-panel-header
          >
          <v-expansion-panel-content
            class="pt-5 mb-2"
            style="
              box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
              border-radius: 5px;
            "
          >
            <v-row>
              <v-col cols="12" sm="3" :class="$vuetify.breakpoint.name === 'xs' ? 'px-1' : ''"
                v-for="(item, key) in total" :key="key">
                <v-card class="box-shadow border-radius pa-2">
                  <v-card-actions class="pt-2 px-0">
                    <div style="width:100%">
                      <v-card-title
                        class="font-weight-bold text--secondary px-0 py-0 pb-1"
                        :style="$vuetify.breakpoint.name === 'xs' ? 'font-size: 11px; line-height: 1; height: 30px' : ''"
                        :class="$vuetify.breakpoint.name === 'xs' ? '' : 'body-2'"
                      >
                        {{ item.label }}
                        <v-spacer></v-spacer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="#d31145"
                              v-if="item.note.title != ''"
                              v-on="on"
                              v-bind="attrs"
                              size="20"
                              >mdi-help-circle</v-icon
                            >
                          </template>
                          <div class="d-flex flex-column">
                            <span class="font-weight-bold"><b>{{item.note.title}}</b></span>
                            <span>{{ item.note.content }}</span>
                          </div>
                        </v-tooltip>
                      </v-card-title>
                      <v-card-text :class="$vuetify.breakpoint.name === 'xs' ? 'title text-second px-0' : 'headline text-second px-0'">
                        {{ item.value }}
                      </v-card-text>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- ==== LIST DATA PENJUALAN HARIAN ==== -->
      <div v-show="process.run && list.length < 1">
        <v-skeleton-loader
          :loading="process.run"
          transition="scale-transition"
          :tile="false"
          type="table-tbody"
        >
        </v-skeleton-loader>
      </div>

      <!-- ==== COMPONENT BASE TABLE ==== -->
      <base-table
        v-on:onResetFilter="resetFilter"
        v-on:onRetrieveDataStart="responseDataStart"
        v-on:onRetrieveDataEnd="responseDataEnd"
        v-show="displayBaseTable"
        :isExternalFilterButton="true"
        :isFilterOnHeader="true"
        ref="base-table"
        dateType="date"
        dateFormat="DD MMMM YYYY"
        :customHeight="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 'calc(100vh - 450px)'"
        :filterHeader="filterHeader_salesDaily"
        :single-select="singleSelect"
        :headers="headers"
        :retrieveDataURL="`franchise/report/harian?store_id=${selected_store_id}`"
        filterType="laporan"
      >

        <template v-slot:[`item.total_product_qty_sold`]="{ item }">
          {{ item.total_product_qty_sold | num_format }}
        </template>

        <template v-slot:[`item.total_product_price`]="{ item }">
          {{ item.total_product_price }}
        </template>

        <template v-slot:[`item.total_product_purchase_price`]="{ item }">
          {{ item.total_product_purchase_price }}
        </template>

        <template v-slot:[`item.total_bruto_price`]="{ item }">
          {{ item.total_bruto_price }}
        </template>

        <template v-slot:[`item.disount_sum`]="{ item }">
          {{ item.disount_sum }}
        </template>

        <template v-slot:[`item.total_charge_nominal`]="{ item }">
          {{ item.total_charge_nominal }}
        </template>

        <template v-slot:[`item.total_tax_nominal`]="{ item }">
          {{ item.total_tax_nominal }}
        </template>

        <template v-slot:[`item.other_cost`]="{ item }">
          {{ item.other_cost }}
        </template>

        <template v-slot:[`item.total_netto`]="{ item }">
          {{ item.total_netto }}
        </template>

        <template v-slot:filterTitle>
          Filter Ringkasan Penjualan Harian
        </template>

        <template v-slot:customFilter>
          <VueCtkDateTimePicker
            v-model="filterdate"
            label="Pilih Tanggal"
            :range="false"
            noClearButton
            color="#d31145"
            format="YYYY-MM-DD"
            formatted="ll"
            onlyDate
            locale="id"
            :max-date="todays"
            :custom-shortcuts="[
              { key: 'today', label: 'Hari ini', value: 'day' },
              { key: 'lastday', label: 'kemarin', value: '-day' },
            ]"
          />
        </template>
      </base-table>

      <!-- ==== COMPONENT DATA KOSONG ==== -->
      <Empty v-show="emptyData" />
    </div>
  </div>
</template>

<script>
import { get, post, put, destroy, exportExcel } from "@/service/Axios";
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import moment from "moment";
import Export from "@/components/Export";
export default {
  data() {
    return {
      panel: "",
      todays: new Date().toISOString().substr(0, 10),
      /**
       * DATA COMPONENT BASETABLE
       */

      displayBaseTable: false,
      emptyData: false,
      singleSelect: true,
      dataPagination: {
        current: 0,
        limit: 0,
        total_data: 0,
        page: 1,
        itemsPerPage: 10,
      },

      headers: [
        {
          text: "Waktu",
          align: "center",
          width: 90,
          value: "input_datetime",
        },
        {
          text: "Nama Pegawai",
          align: "left",
          width: 200,
          value: "complete_user_name",
        },
        {
          text: "Nomor Pesanan",
          align: "left",
          width: 200,
          value: "number",
        },
        {
          text: "Nomor Transaksi",
          align: "left",
          width: 180,
          value: "transaction_number",
        },
        {
          text: "Metode Pembayaran",
          align: "left",
          width: 180,
          value: "payment_method",
        },
        {
          text: "Tipe Penjualan",
          align: "left",
          width: 180,
          value: "type",
        },
        {
          text: "Produk Terjual",
          align: "end",
          width: 180,
          value: "total_product_qty_sold",
        },
        {
          text: "Total Harga Jual (Rp)",
          align: "end",
          width: 180,
          value: "total_product_price",
        },
        {
          text: "Total Harga Beli (Rp)",
          align: "end",
          width: 180,
          value: "total_product_purchase_price",
        },
        { text: "Total Bruto (Rp)", align: "end", width: 180, value: "total_bruto_price" },
        {
          text: "Total Diskon (Rp)",
          align: "end",
          width: 180,
          value: "disount_sum",
        },
        {
          text: "Total Servis (Rp)",
          align: "end",
          width: 180,
          value: "total_charge_nominal",
        },
        {
          text: "Total PPN (Rp)",
          align: "end",
          width: 180,
          value: "total_tax_nominal",
        },
        {
          text: "Total Biaya Tambahan (Rp)",
          align: "end",
          width: 200,
          value: "other_cost",
        },
        {
          text: "Pembulatan (Rp)",
          align: "end",
          width: 200,
          value: "round_nominal_grand_total",
        },
        {
          text: "Grand Total (Rp)",
          align: "end",
          width: 200,
          value: "grand_total",
        },
        {
          text: "Grand Netto (Rp)",
          align: "end",
          width: 200,
          value: "total_nett_price",
        },
      ],

      /**
       * END DATA COMPONENT BASETABLE
       */

      hari: new Date().toISOString().substr(0, 10),
      filterstore: "",
      filternamestore: "",
      filtercashier: "",
      filternamecashier: "",
      filterdate: moment(new Date().toISOString().substr(0, 10)).format(
        "YYYY-MM-DD"
      ),
      options: {},
      picker: "",
      loading: false,
      totalData: 8,
      menuDate: false,

      process: {
        run: false,
        loading: false,
      },
      dialog: {
        filtered: false,
      },
      selected: {
        pagination: 0,
      },
      filterSave: {
        date: "",
        cashier: "",
        store: "",
      },
      status: {
        showTimeRangePanel: false,
        date: false,
        cashier: false,
        store: false,
      },
      store: [],
      cashier: [],
      list: [],
      total: {},
      pagination: {
        data: {},
        page: "",
      },
      limit: 10,
      page: 1,
      totalData: 0,
    };
  },
  components: {
    Empty,
    Pagination,
    Export,
  },
  computed: {
    filterHeader_salesDaily() {
      return [
        {
          type: "custom",
          column: "",
          valueDefault: "",
          label: "",
          class: "",
        },

        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
      ];
    },

    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    dateDaily() {
      return this.picker ? moment(this.picker).format("LL") : "";
    },

    selected_store_id () {
      return JSON.parse(localStorage.getItem('selected_store_id'));
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  watch: {
    // 'selected.pagination': function(val) {
    //   this.initialize(val+1)
    // },
    // options:{
    //   handler(){
    //     this.initialize()
    //   },
    //   deep:true
    // },
  },
  created() {
    // this.initialize()
    this.fetchStore();
    this.fetchUser();
  },
  methods: {
    /**
     * EVENTS
     */
    resetFilter() {
      this.$refs["base-table"].params.date &&
        delete this.$refs["base-table"].params.date;
      // this.$refs["base-table"].params.date2 &&
      //   delete this.$refs["base-table"].params.date2;

      this.status.date = false;
      this.filterdate = "";
      
      this.$refs["base-table"].retrieveData();
    },

    clearFilterChip(type) {
      switch (type) {
        case 'date':
          delete this.$refs["base-table"].params["filter[0][type]"];
          delete this.$refs["base-table"].params["filter[0][field]"];
          delete this.$refs["base-table"].params["filter[0][comparison]"];
          delete this.$refs["base-table"].params["filter[0][value]"];
          break;
      }

      this.$refs["base-table"].retrieveData();
      this.resetFilter();
    },

    handleCloseChips(mode) {
      switch (mode) {
        case "date":
          this.status.date = false;
          this.filterdate = "";
          this.resetFilter();
          // this.$refs["base-table"].retrieveData();
          this.clearFilterChip(mode)
          break;
      }
    },

    responseDataStart() {
      this.loading = true;
      this.process.run = true;
      this.displayBaseTable = false;
      this.emptyData = false;
      /**
       * contains handler for filter params
       * date
       */

      //DATE
      // let item = [];
      if (Object.keys(this.filterdate).length > 0) {
        this.$refs["base-table"].params.date = this.filterdate;
        //show chips
        this.status.date = true;
      }
    },

    responseDataEnd(res) {
      this.loading = false;
      this.process.run = false;
      this.fetchSummaryData(this.selected_store_id)
      if (res.data.length <= 0) {
        this.emptyData = true;
        this.displayBaseTable = false;
      } else {
        this.emptyData = false;
        this.displayBaseTable = true;
      }
      this.contentEmpty = "Data tidak ditemukan";
    },

    async fetchSummaryData (store_id) {
      const response  = await get(`franchise/report/harian/summary?`, {
        params: {
          store_id,
          date: this.filterdate
        }
      })

      let res = response.data

      if (res.status == 200) {
        this.total = res.results.data
      }
    },

    responseExport() {
      //send params from base table to base export
      this.$refs["base-export-excel"].params = this.$refs["base-table"].params;
      this.$refs["base-export-pdf"].params = this.$refs["base-table"].params;

      // //KALAU MAU PAKE SEARCH
      // this.$refs["base-export"].params.search = this.$refs[
      //   "base-table"
      // ].searchVal;
    },
    /**
     * END EVENTS
     */

    disabledAfterThisDay(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    async Export() {
      this.process.formExport = true;
      this.process.formExport = false;
    },
    
    openFilter() {
      this.picker = this.filterSave.date;
      this.filterstore = this.filterSave.store;
      this.filtercashier = this.filterSave.cashier;
      this.dialog.filtered = true;
    },
    toFilter() {
      for (let i in this.store) {
        if (this.filterstore === this.store[i].store_id) {
          this.filternamestore = this.store[i].store_name;
        }
      }
      for (let i in this.cashier) {
        if (this.filtercashier === this.cashier[i].user_id) {
          this.filternamecashier = this.cashier[i].user_name;
        }
      }
      this.status.date =
        this.picker !== undefined && this.picker !== "" ? true : false;
      this.status.store =
        this.filterstore !== undefined && this.filterstore !== ""
          ? true
          : false;
      this.status.cashier =
        this.filtercashier !== undefined && this.filtercashier !== ""
          ? true
          : false;

      this.picker = moment(this.picker).format("YYYY-MM-DD HH:mm:ss");
      this.initialize();
      this.filterSave = {
        date: this.picker,
        store: this.filterstore === undefined ? "" : this.filterstore,
        cashier: this.filtercashier === undefined ? "" : this.filtercashier,
      };
      this.dialog.filtered = false;
    },
    changePage(event) {
      if (event === undefined) {
        this.selected.pagination = 0;
      }
    },
    fetchStore() {
      get(`franchise/store`, {
        params: {
          pagination_bool: false,
        },
      })
        .then((response) => {
          if (response.data.status === 200) {
            let AllStore = { store_id: "0", store_name: "Semua Toko" };
            response.data.results.data.unshift(AllStore);
            this.store = response.data.results.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },
    fetchUser() {
      get(`franchise/store/employee`, {
        params: {
          store_id: this.selected_store_id,
          pagination_bool: false,
        },
      })
        .then((response) => {
          if (response.data.status === 200) {
            this.cashier = response.data.data.results;
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            // this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },
    async exportExcel() {
      this.$store.state.loading = true;
      exportFile("GET", `report/report_harian/export`, {
        params: {
          date: this.picker,
          store: this.filterstore,
          cashier: this.filtercashier,
          sort:
            Object.keys(this.options).length > 0
              ? this.options.sortBy.length > 0
                ? this.options.sortBy[0]
                : ""
              : "",
          dir:
            Object.keys(this.options).length > 0
              ? this.options.sortDesc.length > 0
                ? this.options.sortDesc[0]
                  ? "DESC"
                  : "ASC"
                : ""
              : "",
        },
      }).then((response) => {
        let res = response;
        if (res.status === 200) {
          this.$store.state.loading = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Laporan-Penjualan-Per-Hari-${moment().format("DMYYYYHHmmss")}.xls`
          );
          document.body.appendChild(link);
          link.click();
        } else {
          this.$store.state.loading = false;
          this.error.message = "Gagal mengeksport excel.";
        }
      });
    },
  },
};
</script>
<style scoped>
.background-bank-cols {
  background-color: #cfd8dc;
  border: 3px solid #cfd8dc;
  border-radius: 10px;
}

.mx-datepicker-main .mx-datepicker-content {
  margin-left: 0 !important;
}
</style>
